@import '../sass/_constants.sass'
@import '../../node_modules/srs.sharedcomponents/lib/esm/sass/abstracts/_fonts.sass'
@import '../../node_modules/srs.sharedcomponents/lib/esm/sass/abstracts/_mixins.sass'
@import '../sass/_variables.sass'
@import '../../node_modules/srs.sharedcomponents/lib/esm/sass/components/__components.sass'
@import '../../node_modules/srs.sharedcomponents/lib/esm/sass/layout/__layout.sass'
@import '../../node_modules/srs.sharedcomponents/lib/esm/sass/pages/__pages.sass'
@import '../../node_modules/srs.sharedcomponents/lib/esm/sass/main.sass'

.header__row
    .col-xl-6
       width: 81% !important
.header__button
    font-weight: bold !important
    padding-right: 1.4rem !important
.header__welcome-text
    color: #000 !important
    font-weight: 700 !important
    margin-bottom: 10px !important
    margin-left: 15px !important
    margin-top: 0px !important
#headerLogoImg
    height: 125px !important